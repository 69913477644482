<template>
  <div
    id="zuimei-issue-contents"
    class="d-flex flex-column mx-4"
  >
    <v-card
      class="mb-4 rounded px-4"
      color="white"
      flat
      tile
    >
      <v-tabs
        v-model="currTab"
        background-color="transparent"
      >
        <v-tab
          v-for="(tab, tabIndex) in tabList"
          :key="tabIndex"
          :value="tab.name"
          :href="`#${tab.name}`"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
    </v-card>
    <div
      class="d-flex flex-column"
    >
      <v-tabs-items v-model="currTab">
        <v-tab-item
          v-for="(tab, tabIndex) in tabList"
          :key="tabIndex"
          :value="tab.name"
          class="grey lighten-4"
        >
          <template
            v-if="tab.items.length"
          >
            <content-card
              v-for="(item, index) in tab.items"
              :key="`cn_${index}`"
              :item="item"
              @change="onContentChange"
              @remove="onContentRemove"
            />
            <div
              v-if="tab.pagination.length"
              class="d-flex flex-row mt-6 mb-8"
            >
              <v-pagination
                v-model="tab.pagination.current"
                :length="tab.pagination.length"
                :total-visible="tab.pagination.totalVisible"
                @input="changePagination($event, tab)"
              />
            </div>
          </template>
          <template
            v-else
          >
            <div class="co-flex-col co-juestify-center co-items-center co-bg-white rounded pa-8">
              <span class="co-text-md co-text-gray-500">
                {{ tab.empty }}
              </span>
            </div>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  import lib from '@/api/co.lib'
  import mixPreset from '@/pages/mixins/home/mix.home.preset'

  const parts = mixPreset.Parts
  const names = mixPreset.Names
  export default {
    name: 'SectionIssueCenterContents',
    components: {
      ContentCard: () => import('@/pages/sections/home/ContentCard.vue'),
    },
    data () {
      return {
        currTab: names.ALL,
        tabList: [
          parts[names.ALL],
          parts[names.DYNAMIC],
          parts[names.ATTENTION],
          parts[names.COLLECTION],
          parts[names.LIKE]
        ],
        handlers: {
          [names.ALL]: this.configAllParams,
          [names.DYNAMIC]: this.configDynamicParams,
          [names.ATTENTION]: this.configAttentionParams,
          [names.COLLECTION]: this.configCollectionParams,
          [names.LIKE]: this.configLikeParams
        },
        toOptions: {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
        },
        pagName: '',
        pagVals: {}
      }
    },
    created () {
      this.pagName = `issue-pag-${names.ALL}`
      this.pagVals = api.comm.jsonToObject(lib.cookie.get(this.pagName))

      this.configContents()
      this.loadContents()
    },
    methods: {
      changeContent (tabIndex = 0, params) {
        const items = this.tabList[tabIndex].items
        const cnt = items.length
        let ind = -1
        for (let i = 0; i < cnt; i++) {
          const item = items[i]
          if (item.contentId === params.contentId) {
            ind = i
            break
          }
        }

        if (ind >= 0) {
          const item = items[ind]
          for (const key in params) {
            this.$set(item, key, params[key])
          }

          this.$set(items, ind, item)
        }
      },
      removeContent (tabIndex = 0, params) {
        const items = this.tabList[tabIndex].items
        const cnt = items.length
        let ind = -1
        for (let i = 0; i < cnt; i++) {
          const item = items[i]
          if (item.contentId === params.contentId) {
            ind = i
            break
          }
        }

        if (ind >= 0) {
          items.splice(ind, 1)
        }
      },
      onContentRemove (ev) {
        this.removeContent(0, ev)
        this.removeContent(1, ev)
      },
      onContentChange (ev) {
        // console.log('onContentChange ev: %o', ev)
        this.changeContent(0, ev)
        this.changeContent(1, ev)
      },
      add (item) {
        const part0 = this.tabList[0]
        const part1 = this.tabList[1]
        part0.items.unshift(item)
        part1.items.unshift(item)
      },
      changePagination (number, tab) {
        const params = tab.params
        const data = tab.params.data
        const index = parseInt(number, 10) || 1

        tab.pagination.current = index
        data.currentPage = index

        const name = `issue-pag-${tab.name}`
        lib.cookie.set(name, JSON.stringify(tab.pagination))

        params.reload = true
        api.httpx.getItems(params)

        const target = '#zuimei-issue-contents'
        this.$vuetify.goTo(target, this.toOptions)
      },
      onContent (ev, navigation) {
        const item = ev.item
        this.toContent(item, navigation)
      },
      toContent (item, navigation) {
        const params = {
          name: navigation.name,
          contentId: item.contentId,
          type: item.type,
          title: item.title
        }

        const name = mixPreset.DetailPages.Hot
        lib.cookie.set(name, JSON.stringify(params))
        this.$router.push({ name })
      },
      toContents (ev, navigation) {
        if (!navigation) {
          return
        }

        const params = {
          name: navigation.name,
          title: navigation.title,
          type: navigation.type,
        }
        const name = mixPreset.ListPages.Hot
        lib.cookie.set(name, JSON.stringify(params))
        this.$router.push({ name })
      },
      configContent (obj) {
        const executed = function (res) {
          const data = obj.params.data
          const pagination = obj.pagination
          const pageSize = parseInt(data.pageSize) || 12
          pagination.length = Math.ceil(res.total / pageSize)
        }

        obj.params = web.content.getParams({
          type: obj.type,
          state: web.comm.States.PUBLISH,
          caches: obj.items,
          executed
        })
      },
      configAllParams (obj) {
        const executed = function (res) {
          const data = obj.params.data
          const pagination = obj.pagination
          const pageSize = parseInt(data.pageSize) || 12
          pagination.length = Math.ceil(res.total / pageSize)
        }

        const currentPage = this.pagVals.current || obj.pagination.current
        obj.pagination.current = currentPage

        obj.params = web.content.getParams({
          type: obj.type,
          state: web.comm.States.PUBLISH,
          currentPage,
          sort: 'createTime',
          order: 'desc',
          secSort: '',
          secOrder: '',
          caches: obj.items,
          executed
        })
      },
      configDynamicParams (obj) {
        const executed = function (res) {
          const data = obj.params.data
          const pagination = obj.pagination
          const pageSize = parseInt(data.pageSize) || 12
          pagination.length = Math.ceil(res.total / pageSize)
        }

        obj.params = web.content.getParamsPersonal({
          type: obj.type,
          state: web.comm.States.PUBLISH,
          caches: obj.items,
          executed
        })
      },
      configAttentionParams (obj) {
        const executed = function (res) {
          const data = obj.params.data
          const pagination = obj.pagination
          const pageSize = parseInt(data.pageSize) || 12
          pagination.length = Math.ceil(res.total / pageSize)
        }

        obj.params = web.content.getParamsAttention({
          type: obj.type,
          state: web.comm.States.PUBLISH,
          caches: obj.items,
          executed
        })
      },
      configCollectionParams (obj) {
        const executed = function (res) {
          // console.log('obj.items: %o', obj.items)
          const data = obj.params.data
          const pagination = obj.pagination
          const pageSize = parseInt(data.pageSize) || 12
          pagination.length = Math.ceil(res.total / pageSize)
        }

        obj.params = web.collection.getParamsPersonal({
          caches: obj.items,
          executed
        })
      },
      configLikeParams (obj) {
        const executed = function (res) {
          const data = obj.params.data
          const pagination = obj.pagination
          const pageSize = parseInt(data.pageSize) || 12
          pagination.length = Math.ceil(res.total / pageSize)
        }

        obj.params = web.like.getParamsPersonal({
          caches: obj.items,
          executed
        })
      },
      configContents () {
        for (const i in this.tabList) {
          const tab = this.tabList[i]
          const handler = this.handlers[tab.name]
          if (typeof handler === 'function') {
            handler(tab)
          }
        }
      },
      loadContents (reload = true) {
        for (const i in this.tabList) {
          const item = this.tabList[i]
          item.params.reload = reload
          api.httpx.getItems(item.params)
        }
      }
    }
  }
</script>
